var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mainWrapper clearfix"},[_c('div',{staticClass:"articleMeauleft"},[_c('a-divider',{attrs:{"orientation":"left"}},[_vm._v("题目分类")]),_c('a-tree',{attrs:{"showLine":"","checkStrictly":"","defaultExpandAll":"","replaceFields":_vm.replaceFields,"treeData":_vm.treeDataCategory},on:{"select":_vm.selectCategory}})],1),_c('div',{staticClass:"articleMainRight"},[_c('div',{staticClass:"pxkeji-right-box"},[_c('a-form',{staticClass:"ant-advanced-search-form"},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"题目内容"}},[_c('a-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.search.question),callback:function ($$v) {_vm.$set(_vm.search, "question", $$v)},expression:"search.question"}})],1)],1),_c('a-col',{style:({ textAlign: 'left' }),attrs:{"span":8}},[_c('a-form-item',[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.searchList}},[_vm._v("搜索")])],1)],1)],1)],1)],1),_c('div',{staticClass:"pxkeji-right-box"},[_c('a-table',{attrs:{"rowKey":"id","size":"small","bordered":"","columns":_vm.list.columns,"pagination":_vm.list.pagination,"dataSource":_vm.list.datas,"loading":_vm.isloading,"rowSelection":{
            type: 'checkbox',
            columnWidth: 40,
            onSelect: _vm.onRowSelect,
            onSelectAll: _vm.onSelectAllRow,
            selectedRowKeys: _vm.selectRows,
          },"locale":{ emptyText: '暂无数据' }},on:{"change":_vm.onPageChange},scopedSlots:_vm._u([{key:"subjectType",fn:function(subjectType){return [(subjectType == 0)?_c('span',[_vm._v("单选题")]):_vm._e(),(subjectType == 1)?_c('span',[_vm._v("多选题")]):_vm._e(),(subjectType == 2)?_c('span',[_vm._v("判断题")]):_vm._e()]}},{key:"questContent",fn:function(questContent){return [_c('span',{domProps:{"innerHTML":_vm._s(questContent)}})]}}])})],1)])]),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{style:({ textAlign: 'center' }),attrs:{"span":24}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.choseBack}},[_vm._v("选择并返回")]),_c('pxkeji-btn',{staticStyle:{"margin-left":"10px"},attrs:{"name":"取消","type":"default"},on:{"click":_vm.cancelClick}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }