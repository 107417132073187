<template>
  <div class="courseContent">
    <div class="articleMeauleft">
      <a-divider orientation="left">课程分类</a-divider>
      <a-tree
        :replaceFields="{
          title: 'name',
          key: 'id',
          children: 'children',
        }"
        :treeData="typeList"
        @select="onTypeSelect"
      />
    </div>
    <div class="articleMainRight">
      <div class="pxkeji-right-box">
        <a-form class="ant-advanced-search-form">
          <a-row :gutter="24">
            <a-col :span="6">
              <a-form-item label="课程名称">
                <a-input placeholder="请输入" v-model="search.courseName" />
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="课程状态">
                <a-select v-model="search.status">
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option value="0">未发布</a-select-option>
                  <a-select-option value="1">已发布</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="筛选条件">
                <a-select @change="handleChange" allowClear>
                  <a-select-option value="1">好课推荐</a-select-option>
                  <a-select-option value="2">免费好课</a-select-option>
                  <a-select-option value="3">热门课程</a-select-option>
                  <a-select-option value="4">必学好课</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="3" :style="{ textAlign: 'left' }">
              <a-form-item>
                <a-button
                  type="primary"
                  :loading="isloading"
                  @click="searchList"
                >
                  搜索
                </a-button>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
        <div>
          <pxkeji-btn
            name="添加"
            :roles="['课程-课程添加']"
            class="pxkeji_basic_btn"
            @click="showInfo('0')"
            type="primary"
          />
          <pxkeji-btn
            name="发布"
            :disabled="selectRows.length == 0"
            @click="batchPublish(1)"
            :roles="['课程-课程发布']"
            class="pxkeji_basic_btn"
          />
          <pxkeji-btn
            name="下架"
            :disabled="selectRows.length == 0"
            @click="batchPublish(0)"
            :roles="['课程-课程发布']"
            class="pxkeji_basic_btn"
          />
          <pxkeji-btn
            name="批量删除"
            :disabled="selectRows.length == 0"
            @click="batchDelete()"
            :roles="['课程-课程删除']"
            class="pxkeji_basic_btn"
          />
          <span style="margin-left: 8px">
            <template v-if="selectRows.length > 0">
              {{ `已选择 ${selectRows.length} 项` }}
              <a-button type="link" @click="cancelSelect"> 取消选择 </a-button>
            </template>
          </span>
        </div>
      </div>

      <div>
        <a-table
          rowKey="id"
          size="small"
          bordered
          :columns="list.columns"
          :pagination="list.pagination"
          @change="onPageChange"
          :dataSource="list.datas"
          :loading="isloading"
          :rowSelection="{
            type: 'checkbox',
            columnWidth: 40,
            onChange: onRowChange,
            selectedRowKeys: selectRows,
          }"
          :customRow="customRow"
          :rowClassName="setRowClassName"
          :locale="{ emptyText: '暂无数据' }"
          :scroll="{ y: list.tableHeight }"
        >
          <span slot="status" slot-scope="status">
            <a-tag v-if="status == 1" color="green">已发布</a-tag>
            <a-tag v-else-if="status == 0" color="red">未发布</a-tag>
            <a-tag v-else color="gray">已删除</a-tag>
          </span>
          <span slot="recommend" slot-scope="recommend">
            <a-switch
              checkedChildren="开"
              unCheckedChildren="关"
              :loading="switchLoading"
              v-model="recommend.recommend"
              @change="switchDisplay(recommend)"
            />
          </span>
          <span slot="open" slot-scope="open">
            <a-switch
              checkedChildren="开"
              unCheckedChildren="关"
              :loading="switchLoading"
              v-model="open.open"
              @change="switchOpen(open)"
            />
          </span>
          <span slot="required" slot-scope="required">
            <a-switch
              checkedChildren="开"
              unCheckedChildren="关"
              :loading="switchLoading"
              v-model="required.required"
              @change="switchRequired(required)"
            />
          </span>

          <span slot="action" slot-scope="action">
            <pxkeji-btn
              name="课时管理"
              @click="handleclassHour(action.id, action.name)"
              size="small"
              type="primary"
              :roles="['课程-章节课时管理']"
            />
            <!-- <pxkeji-btn
              name="上移"
              @click="sortCourse(action.id, 1)"
              size="small"
              type="default"
              v-if="innerWidth >= 1600"
              :roles="['课程-课程修改']"
            />
            <pxkeji-btn
              name="下移"
              @click="sortCourse(action.id, 0)"
              size="small"
              type="default"
              v-if="innerWidth >= 1600"
              :roles="['课程-课程修改']"
            /> -->
            <pxkeji-btn
              name="编辑"
              @click="showInfo(action.id)"
              size="small"
              type="default"
              :roles="['课程-课程修改']"
            />
            <pxkeji-btn
              name="预览"
              @click="showCourseVideo(action.id)"
              size="small"
              type="default"
              :roles="['课程-审核视频']"
            />
            <!-- <a-popconfirm
              title="是否确认删除此课程"
              @confirm="delInfo(action.id)"
              v-if="innerWidth >= 1600"
            >
              <a-icon slot="icon" type="question-circle-o" />
              <pxkeji-btn
                name="删除"
                size="small"
                type="danger"
                :loading="isloading"
                :roles="['课程-课程删除']"
              />
            </a-popconfirm> -->
            <template>
              <a-dropdown>
                <a-menu slot="overlay">
                  <a-menu-item key="1">
                    <pxkeji-btn
                      name="生成封面"
                      @click="toCreatePic(action.id, action.name)"
                      size="small"
                      type="default"
                      :roles="['课程-课程修改']"
                    />
                  </a-menu-item>
                  <a-menu-item key="2">
                    <pxkeji-btn
                      name="上移"
                      @click="sortCourse(action.id, 1)"
                      size="small"
                      type="default"
                      :roles="['课程-课程修改']"
                    />
                  </a-menu-item>
                  <a-menu-item key="3">
                    <pxkeji-btn
                      name="下移"
                      @click="sortCourse(action.id, 0)"
                      size="small"
                      type="default"
                      :roles="['课程-课程修改']"
                    />
                  </a-menu-item>
                  <a-menu-item key="4">
                    <pxkeji-btn
                      name="推送"
                      size="small"
                      @click="doPush(action.id)"
                    />
                  </a-menu-item>
                  <!-- <a-menu-item key="4">
                    <pxkeji-btn
                      name="学生明细"
                      @click="handledetails(action.id)"
                      size="small"
                      type="default"
                    />
                  </a-menu-item> -->
                  <a-menu-item key="5">
                    <a-popconfirm
                      title="是否确认删除此课程"
                      @confirm="delInfo(action.id)"
                    >
                      <a-icon slot="icon" type="question-circle-o" />
                      <pxkeji-btn
                        name="删除"
                        size="small"
                        type="danger"
                        :loading="isloading"
                        :roles="['课程-课程删除']"
                      />
                    </a-popconfirm>
                  </a-menu-item>
                  <!-- <a-menu-item key="6">
                    <pxkeji-btn
                      name="关联题目"
                      v-if="action.parentId != 0"
                      size="small"
                      @click="addSubjectInfo(action.id)"
                      type="primary"
                      :roles="['课程-课程题目管理']"
                    />
                  </a-menu-item> -->
                </a-menu>
                <a-button size="small" type="link"> ... </a-button>
              </a-dropdown>
            </template>
          </span>
        </a-table>
      </div>
    </div>
    <a-modal
      :title="info.title"
      v-model="info.visible"
      width="1100px"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
      :afterClose="modalClose"
    >
      <CourseInfo
        @callbackMethod="save"
        :id="info.id"
        :selectTypeId="search.typeId"
      />
    </a-modal>
    <a-modal
      title="课时管理"
      v-model="classHour.visible"
      width="80%"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
      :afterClose="modalClose"
    >
      <CourseChapterHours
        @callbackMethod="save"
        :courseId="classHour.id"
        :courseName="classHour.courseName"
      />
    </a-modal>
    <a-modal
      title="学生明细"
      v-model="detail.visible"
      width="80%"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
      :afterClose="modalClose"
    >
      <studentdetaillistInfo @callbackMethod="save" :id="detail.id" />
    </a-modal>
    <a-modal
      :title="subjectInfo.title"
      v-model="subjectInfo.visible"
      width="90%"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <subjectInfo @callbackMethod="subjectInfoSubmit" :id="subjectInfo.id" />
    </a-modal>
    <a-modal
      title="视频预览"
      v-model="videoUrl.visible"
      width="90%"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <courseHourVideo @callbackMethod="closeVideoUrl" :id="videoUrl.id" />
    </a-modal>
    <a-modal
      title="封面图配置"
      v-model="createPic.visible"
      width="900px"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <CoursePicConfig
        @callbackMethod="closeCreatePic"
        :id="createPic.id"
        :name="createPic.name"
      />
    </a-modal>
  </div>
</template>
<script>
import CourseInfo from "./CourseInfo.vue"; //编辑
import CourseChapterHours from "./CourseChapterHours.vue"; //课时管理列表
import studentdetaillistInfo from "./studentdetaillistInfo.vue"; //学生明细
import subjectInfo from "./courseSubject.vue"; //
import courseHourVideo from "./courseHourVideo.vue"; //
import CoursePicConfig from "./CoursePicConfig.vue"; //

const columns = [
  {
    dataIndex: "name",
    align: "left",
    title: "课程名称",
    ellipsis: true,
  },
  {
    dataIndex: "typeName",
    title: "所属分类",
    ellipsis: true,
  },
  // {
  //   key: "open",
  //   align: "center",
  //   title: "公开",
  //   width: "7%",
  //   scopedSlots: { customRender: "open" },
  // },
  // {
  //   key: "required",
  //   align: "center",
  //   title: "必修",
  //   width: "7%",
  //   scopedSlots: { customRender: "required" },
  // },
  {
    dataIndex: "status",
    align: "center",
    title: "状态",
    width: "7%",
    scopedSlots: { customRender: "status" },
  },
  {
    key: "recommend",
    align: "center",
    title: "推荐",
    width: "7%",
    scopedSlots: { customRender: "recommend" },
  },
  {
    dataIndex: "createTime",
    align: "center",
    title: "发布日期",
    width: "13%",
    ellipsis: true,
  },
  {
    title: "操作",
    key: "action",
    width: "18%",
    scopedSlots: { customRender: "action" },
  },
];

let _this;
export default {
  name: "CourseList",
  data() {
    return {
      innerWidth: window.innerWidth,
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: true,
          showSizeChanger: true,
          total: 0,
          current: 1,
          pageSize: 10,
          showTotal: (total) => `共 ${total} 条`,
        },
        tableHeight: window.innerHeight - 350,
      },
      typeList: [],
      selectRowId: "",
      //查询条件
      search: {
        status: "",
        courseName: "",
        typeId: "",
        condition: "",
      },
      condition: {
        recommend: "",
        isGratis: "",
        order: "",
        tag: "",
      },
      selectRows: [], //选中项编号
      isloading: false,
      info: {
        title: "",
        visible: false,
        id: 0,
      },
      subjectInfo: {
        visible: false,
        id: 0,
        title: "关联题目",
      },
      videoUrl: {
        visible: false,
        id: 0,
      },
      switchLoading: false, //开关等待
      classHour: {
        //课时管理
        visible: false,
        id: 0,
        courseName: "",
      },
      detail: {
        //学生明细
        visible: false,
      },
      createPic: {
        visible: false,
        id: "",
        name: "",
      },
    };
  },
  components: {
    CourseInfo,
    CourseChapterHours,
    studentdetaillistInfo,
    subjectInfo,
    courseHourVideo,
    CoursePicConfig,
  },
  mounted: function () {
    _this = this;
    if (window.innerWidth >= 1600) {
      _this.list.pagination.pageSize = 20;
    }
    window.onresize = () => {
      _this.list.tableHeight = window.innerHeight - 293;
      _this.innerWidth = window.innerWidth;
      if (window.innerWidth >= 1600) {
        _this.list.pagination.pageSize = 20;
      } else {
        _this.list.pagination.pageSize = 10;
      }
    };
    _this.getTypeTree();
    _this.GetList();
  },
  methods: {
    async getTypeTree() {
      let result = await _this.$api.courseType.getUseTypeTree();
      if (result.errorCode == _this.$msg.responseCode.success) {
        _this.typeList = result.data;
      } else {
        _this.$message.error(result.errorMsg);
      }
    },
    onTypeSelect(selectKeys) {
      _this.search.typeId = selectKeys[0];
      _this.searchList();
    },
    addSubjectInfo(id) {
      _this.subjectInfo.id = id;
      _this.subjectInfo.visible = true;
    },
    subjectInfoSubmit() {
      _this.subjectInfo.visible = false;
    },
    showCourseVideo(id) {
      _this.videoUrl.id = id;
      _this.videoUrl.visible = true;
    },
    closeVideoUrl() {
      _this.videoUrl.visible = false;
    },
    customRow(record) {
      return {
        on: {
          click: () => {
            _this.selectRowId = record.id;
          },
        },
      };
    },
    setRowClassName(record) {
      return _this.selectRowId == record.id ? "selectRow" : "";
    },
    doPush(id) {
      _this.isloading = true;
      _this.$api.course
        .pushMessage(id)
        .then((res) => {
          _this.isloading = false;
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
          } else {
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    GetList() {
      //获取列表
      _this.isloading = true;
      _this.$api.course
        .getPageList(
          _this.list.pagination.current,
          _this.list.pagination.pageSize,
          0,
          _this.search.typeId,
          _this.search.courseName,
          _this.search.status,
          _this.condition.recommend,
          _this.condition.isGratis,
          _this.condition.order,
          _this.condition.tag
        )
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.list.pagination.total = Number(res.data.total);
            res.data.records.forEach((value, index) => {
              res.data.records[index].recommend = this.$commen.changeIntBoolean(
                value.recommend
              );
              res.data.records[index].open = this.$commen.changeIntBoolean(
                value.open
              );
              res.data.records[index].required = this.$commen.changeIntBoolean(
                value.required
              );
            });
            _this.list.datas = res.data.records;
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
          _this.modalClose();
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    onPageChange(page) {
      //页面切换回调
      _this.list.pagination.current = page.current;
      _this.list.pagination.pageSize = page.pageSize;
      _this.GetList();
    },
    switchDisplay(e) {
      _this.switchLoading = true;
      _this.$api.course
        .updateRecommendById(e.id, _this.$commen.changeBooleanInt(e.recommend))
        .then((res) => {
          _this.switchLoading = false;
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    switchOpen(e) {
      _this.switchLoading = true;
      _this.$api.course
        .updateOpenById(e.id, _this.$commen.changeBooleanInt(e.open))
        .then((res) => {
          _this.switchLoading = false;
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    switchRequired(e) {
      _this.switchLoading = true;
      _this.$api.course
        .updateRequiredById(e.id, e.required)
        .then((res) => {
          _this.switchLoading = false;
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    sortCourse(id, isUp) {
      _this.isloading = true;
      _this.$api.course
        .sortCourse(id, isUp)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    onRowChange(selectedRowKeys) {
      _this.selectRows = selectedRowKeys;
    },
    cancelSelect() {
      _this.selectRows = [];
    },
    searchList() {
      _this.list.pagination.current = 1;
      _this.GetList();
    },
    showInfo(id) {
      _this.info.title = id == "0" ? "添加" : "编辑";
      _this.info.visible = true;
      _this.info.id = id;
      _this.selectRowId = id;
    },
    //发布
    batchPublish(status) {
      let msg = status == 1 ? "发布" : "下架";
      _this.$confirm({
        title: "提示",
        content: `您确定要${msg}已选择的课程吗？`,
        okText: "确定",
        cancelText: "取消",
        onOk() {
          _this.isDisable(_this.selectRows.join(","), status);
        },
        onCancel() {},
      });
    },
    /**禁用启用的方法 */
    isDisable(ids, status) {
      _this.isloading = true;
      _this.$api.course
        .updateStatusByIds(ids, status)
        .then((res) => {
          _this.isloading = false;
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.selectRows = [];
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    /**删除 */
    batchDelete() {
      _this.$confirm({
        title: "提示",
        content: `您确定要删除已选择的课程吗？`,
        okText: "确定",
        cancelText: "取消",
        onOk() {
          _this.delInfo(_this.selectRows.join(","));
        },
        onCancel() {},
      });
    },
    delInfo(ids) {
      _this.isloading = true;
      _this.$api.course
        .removeById(ids)
        .then((res) => {
          _this.isloading = false;
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    /**课时管理 */
    handleclassHour(id, courseName) {
      _this.classHour.visible = true;
      _this.classHour.id = id + "";
      _this.classHour.courseName = courseName;
      _this.selectRowId = id;
    },
    /**学生明细 */
    handledetails(id) {
      _this.detail.visible = true;
      _this.detail.id = id;
      _this.selectRowId = id;
    },
    modalClose() {
      setTimeout(() => {
        _this.selectRowId = "";
      }, 3000);
    },
    //子组件传值
    save() {
      _this.info.visible = false;
      _this.GetList();
    },
    handleChange(value) {
      _this.condition = {};
      if (value == 1) {
        _this.condition.recommend = 1;
      } else if (value == 2) {
        _this.condition.recommend = 1;
        _this.condition.isGratis = 1;
      } else if (value == 3) {
        _this.condition.order = 2;
      } else if (value == 4) {
        _this.condition.order = 2;
        _this.condition.tag = "必学好课";
      }
    },
    toCreatePic(id, name) {
      _this.createPic.id = id;
      _this.createPic.name = name;
      _this.createPic.visible = true;
    },
    closeCreatePic() {
      _this.createPic.visible = false;
    },
  },
};
</script>

<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.courseContent:after {
  content: "";
  height: 0;
  display: block;
  visibility: hidden;
  clear: both;
}
.articleMeauleft {
  width: 20%;
  padding: 0 1%;
  background: #fff;
  float: left;
  margin-top: 10px;
}
.articleMainRight {
  width: 80%;
  float: right;
}
</style>

