<template>
  <div>
    <div class="pxkeji-right-box">
      <pxkeji-btn
        name="添加章节"
        class="pxkeji_basic_btn"
        @click="addChapter(0, 0)"
        type="primary"
        v-if="showChapterAdd"
        :roles="['']"
      />
      <pxkeji-btn
        name="添加课时"
        class="pxkeji_basic_btn"
        @click="addHour(0, 0)"
        type="primary"
        v-if="showHourAdd"
        :roles="['']"
      />
      <a-table
        rowKey="id"
        childrenColumnName="hourList"
        expandRowByClick
        :expandedRowKeys="expandedRows"
        :columns="list.columns"
        :dataSource="list.datas"
        :pagination="false"
        :loading="isloading"
        :locale="{ emptyText: '暂无数据' }"
      >
        <span slot="action" slot-scope="action">
          <pxkeji-btn
            name="编辑章节"
            size="small"
            @click="addChapter(action.id, action.parentId)"
            v-if="action.dataType == '章节'"
          />
          <pxkeji-btn
            name="添加课时"
            size="small"
            @click="addHour(0, action.id)"
            type="primary"
            v-if="action.dataType == '章节'"
          />
          <pxkeji-btn
            name="编辑课时"
            size="small"
            @click="addHour(action.id, action.chapterId)"
            type="primary"
            v-if="action.dataType == '课时'"
          />
          <a-popconfirm @confirm="deleteChapter(action.id)">
            <div slot="title">是否确认删除此章节？</div>
            <a-icon slot="icon" type="question-circle-o" />
            <pxkeji-btn
              name="删除章节"
              size="small"
              type="danger"
              v-if="action.dataType == '章节'"
            />
          </a-popconfirm>
          <a-popconfirm @confirm="deleteHour(action.id)">
            <div slot="title">是否确认删除此课时？</div>
            <a-icon slot="icon" type="question-circle-o" />
            <pxkeji-btn
              name="删除课时"
              size="small"
              type="danger"
              v-if="action.dataType == '课时'"
            />
          </a-popconfirm>
          <pxkeji-btn
            name="上移"
            size="small"
            @click="handleMove(action.id, 1, action.dataType == '课时' ? 1 : 0)"
            type="default"
          />
          <pxkeji-btn
            name="下移"
            size="small"
            @click="handleMove(action.id, 0, action.dataType == '课时' ? 1 : 0)"
            type="default"
          />
        </span>
      </a-table>
    </div>

    <a-modal
      :title="chapterInfo.title"
      v-model="chapterInfo.visible"
      width="700px"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <CourseChapterInfo @callbackMethod="saveBack" :infos="chapterInfo" />
    </a-modal>
    <a-modal
      :title="hourInfo.title"
      v-model="hourInfo.visible"
      width="60%"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <CourseHourInfo @callbackMethod="saveBack" :infos="hourInfo" />
    </a-modal>
    <!-- <a-modal
      :title="subjectInfo.title"
      v-model="subjectInfo.visible"
      width="90%"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <subjectInfo
        @callbackMethod="subjectInfoSubmit"
        :id="subjectInfo.id"
        :courseId="this.id"
        :zjid="subjectInfo.zjId"
      />
    </a-modal> -->
  </div>
</template>
<script>
import CourseChapterInfo from "./CourseChapterInfo.vue"; //章节
import CourseHourInfo from "./CourseHourInfo.vue"; //课时
// import subjectInfo from "./courseHourSubject.vue"; //课时

var columns = [
  {
    dataIndex: "title",
    align: "left",
    title: "名称",
  },
  {
    dataIndex: "dataType",
    align: "left",
    title: "类型",
  },
  {
    key: "action",
    align: "right",
    title: "操作",
    scopedSlots: { customRender: "action" },
  },
];
let _this;
export default {
  name: "CourseChapterHours",
  props: {
    courseId: String,
    courseName:String,
  },
  data() {
    return {
      list: {
        columns, //表头
        datas: [], //数据
      },
      isloading: false,
      showChapterAdd: true,
      showHourAdd: true,
      expandedRows: [],
      chapterInfo: {
        visible: false,
        title: "",
        courseId: 0,
        id: 0,
        courseName:"",
        parentId: 0,
      },
      hourInfo: {
        visible: false,
        title: "",
        courseId: 0,
        chapterId: 0,
        courseName:"",
        id: 0,
      },
      subjectInfo: {
        visible: false,
        id: 0,
        zjId: 0,
        title: "选择题目",
      },
    };
  },
  components: {
    CourseChapterInfo,
    CourseHourInfo,
    // subjectInfo,
  },
  mounted: function () {
    _this = this;
    console.log("courseName",_this.courseName)
    _this.getCourseDetail();
  },
  methods: {
    getCourseDetail() {
      //获取列表
      _this.isloading = true;
      _this.$api.course
        .getCourseDetail(_this.courseId)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.list.datas = [];
            if (res.data.chapterList) {
              _this.showHourAdd = false;
              _this.list.datas = res.data.chapterList;
              _this.expandedRows = res.data.chapterList.map((item) => item.id);
              _this.list.datas.forEach((item) => {
                item.dataType = "章节";
                if (item.hourList) {
                  item.hourList.forEach((data) => (data.dataType = "课时"));
                }
              });
            } else if (res.data.hourList) {
              _this.showChapterAdd = false;
              _this.list.datas = res.data.hourList;
              _this.list.datas.forEach((item) => (item.dataType = "课时"));
            }
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    deleteChapter(id) {
      _this.isloading = true;
      _this.$api.course
        .deleteChapter(id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.getCourseDetail();
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    deleteHour(id) {
      _this.isloading = true;
      _this.$api.courseHour
        .deleteById(id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.getCourseDetail();
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    /**添加章节 */
    addChapter(id, parentId) {
      _this.chapterInfo.title = id == 0 ? "添加章节" : "编辑章节";
      _this.chapterInfo.courseId = _this.courseId;
      _this.chapterInfo.id = id;
      _this.chapterInfo.courseName = _this.courseName;
      _this.chapterInfo.parentId = parentId;
      _this.chapterInfo.visible = true;
    },
    /**添加课时 **/
    addHour(id, chapterId) {
      _this.hourInfo.title = id == 0 ? "添加课时" : "编辑课时";
      _this.hourInfo.courseId = _this.courseId;
      _this.hourInfo.chapterId = chapterId;
      _this.hourInfo.courseName = _this.courseName;
      _this.hourInfo.id = id;
      _this.hourInfo.visible = true;
    },
    saveBack() {
      _this.chapterInfo.visible = false;
      _this.hourInfo.visible = false;
      _this.getCourseDetail();
    },
    handleMove(id, isUp, type) {
      _this.isloading = true;
      if (type == 0) {
        _this.$api.course
          .sortChapter(id, isUp)
          .then((res) => {
            if (res.errorCode == _this.$msg.responseCode.success) {
              _this.$message.success(res.errorMsg);
              _this.getCourseDetail();
            } else {
              _this.$message.error(res.errorMsg);
              _this.isloading = false;
            }
          })
          .catch(() => {
            _this.isloading = false;
          });
      } else {
        _this.$api.courseHour
          .sortHour(id, isUp)
          .then((res) => {
            if (res.errorCode == _this.$msg.responseCode.success) {
              _this.$message.success(res.errorMsg);
              _this.getCourseDetail();
            } else {
              _this.$message.error(res.errorMsg);
              _this.isloading = false;
            }
          })
          .catch(() => {
            _this.isloading = false;
          });
      }
    },
  },
};
</script>

<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.cang_sta {
  text-align: center;
}

.cang_sta img {
  width: 35px;
}
</style>

