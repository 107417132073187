<template>
  <div>
    <a-spin :spinning="isloading" tip="加载中...">
      <div class>
        <div class="pxkeji-right-box" style="margin-top: 0">
          <pxkeji-btn
            name="选择学员"
            class="pxkeji_basic_btn"
            @click="chosestudent"
            type="primary"
          />
          <a-form class="ant-advanced-search-form">
            <a-row :gutter="24">
              <a-col :span="6">
                <a-form-item label="学员姓名">
                  <a-input
                    placeholder="学员姓名/手机号"
                    v-model="where.keyword.name"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item label="所属企业">
                  <a-input
                    placeholder="所属企业"
                    v-model="where.keyword.company"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="3" :style="{ textAlign: 'left' }">
                <a-button type="primary" @click="searchList">搜索</a-button>
              </a-col>
            </a-row>
          </a-form>
          <a-table
            :columns="list.columns"
            rowKey="id"
            :pagination="list.pagination"
            @change="onChange"
            :dataSource="list.datas"
            :loading="isloading"
            :locale="{ emptyText: '暂无数据' }"
          >
            <span slot="gender" slot-scope="gender">
              <span v-if="gender == 0"></span>
              <span v-else-if="gender == 1">男</span>
              <span v-else-if="gender == 2">女</span>
            </span>
            <span slot="action" slot-scope="action">
              <pxkeji-btn
                name="删除"
                @click="delInfo(action.id)"
                size="small"
                type="primary"
              />
            </span>
          </a-table>
        </div>
        <!-- <a-modal
          title="选择学员"
          v-model="student.visible"
          width="80%"
          :footer="false"
          destroyOnClose
          :maskClosable="false"
        >
          <chosestudentInfo
            :id="id"
            :infos="{ id: id }"
            @callbackMethod="save"
          />
        </a-modal> -->
      </div>
    </a-spin>
  </div>
</template>

<script>
// import chosestudentInfo from "../enrollment/enroll_students.vue"; //报名学生列表
// import chosestudentInfo from "./chosestudentInfo.vue"; //选择学员
var that;
const columns = [
  
  {
    dataIndex: "fullName",
    key: "fullName",
    align: "center",
    title: "学员名称",
  },
  {
    dataIndex: "telephone",
    key: "telephone",
    align: "center",
    title: "手机号",
  },
  {
    dataIndex: "sex",
    key: "sex",
    align: "center",
    title: "性别",
  },
  // {
  //   dataIndex: "enterprise.name",
  //   key: "enterpriseName",
  //   align: "center",
  //   title: "所属企业",
  // },
  // {
  //   title: "操作",
  //   key: "action",
  //   align: "center",
  //   scopedSlots: { customRender: "action" }
  // }
];
export default {
  props: {
    id: String,
  },
  data() {
    return {
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          //分页器
          showQuickJumper: true,
          hideOnSinglePage: true,
          showSizeChanger: true,
          total: 0,
          defaultCurrent: 1, //页码
          pageSize: 10, //页容
        },
      },
      where: {
        //查询条件
        keyword: {
          //搜索条件
          name: "",
          company: "",
        },
      },
      selectRows: [], //选中项编号
      selectdata: {}, //选择撤机选项
      isloading: false,
      student: {
        //选择学员
        visible: false,
      },
    };
  },
  components: {
    // chosestudentInfo,
  },
  mounted() {
    this.$nextTick(() => {
      that = this;
      this.GetList();
      console.log(this.id);
      if (this.id) this.selectRows = [this.id];
    });
  },
  methods: {
    GetList() {
      this.list.pagination.total = 0;
      this.isloading = true;
      this.clearRows();
      this.$api.order
        .getStudentDetailed(
          this.list.pagination.defaultCurrent,
          this.list.pagination.pageSize,
          this.id,
          this.where.keyword.name,
          this.where.keyword.company
        )
        .then((res) => {
          this.isloading = false;
          if (res.errorCode == this.$msg.responseCode.success) {
            this.list.datas = res.data.records;
            this.list.pagination.total = Number(res.data.total);
          }
        })
        .catch(() => {
          this.isloading = false;
        });
    },
    /**选择学员 */
    chosestudent() {
      this.student.visible = true;
    },
    onChange(page) {
      //页面切换回调
      this.list.pagination.defaultCurrent = page.current;
      this.GetList();
    },
    //table 单选
    onRowChange(selectedRowKeys, selectedRows) {
      this.selectRows = selectedRowKeys;
      this.selectdata = selectedRows;
    },
    clearRows() {
      //清空选中项
      this.selectRows = [];
    },
    searchList() {
      this.list.pagination.defaultCurrent = 1;
      this.GetList();
    },
    clear() {
      //清空搜索条件
      for (var info in this.where.keyword) {
        this.where.keyword[info] = "";
      }
    },
    //子组件传值
    save() {
      this.student.visible = false;
      this.GetList();
    },
  },
};
</script>
<style>
</style>