<template>
  <a-spin :tip="loadingTip" :spinning="isloading">
    <div class>
      <a-form-model
        ref="courseHourForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="课程名称">
          <a-input placeholder="" v-model="infos.courseName" disabled></a-input>
        </a-form-model-item>
        <a-form-model-item label="课时标题" prop="title">
          <a-input
            placeholder="请输入"
            v-model="form.title"
            :maxLength="100"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="播放量" prop="hits">
          <a-input
            placeholder="请输入"
            v-model="form.hits"
            :maxLength="10"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="时长" prop="hourTime">
          <a-input
            placeholder="请输入"
            v-model.number="form.hourTime"
            :maxLength="5"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item
          label="学时"
          prop="hourCount"
          v-if="hourCountType == 2"
        >
          <a-input
            placeholder="请输入"
            v-model.number="form.hourCount"
            :maxLength="5"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="是否试听课时" prop="isAudition">
          <a-switch
            v-model="form.isAudition"
            checked-children="是"
            un-checked-children="否"
          />
        </a-form-model-item>
        <a-form-model-item label="视频资源类型" prop="sourceType">
          <a-radio-group v-model="form.sourceType">
            <template v-for="type in videoTypeList">
              <a-radio :value="type.type" :key="type.type">
                {{ type.name }}
              </a-radio>
            </template>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item
          label="视频地址"
          prop="videoUrl"
          v-if="form.sourceType == 1"
        >
          <a-input
            placeholder="请输入"
            v-model="form.videoUrl"
            :maxLength="100"
          ></a-input>
        </a-form-model-item>

        <a-form-model-item
          label="视频"
          v-if="form.sourceType == 0"
          help="注：仅支持mp4格式，大小在2G以下。"
        >
          <a-upload
            accept="video/mp4"
            :action="$api.file.uploadFile"
            :fileList="fileListVideo"
            :beforeUpload="beforeUpload"
            @change="handleVideoChange"
          >
            <div v-if="fileListVideo.length < 1">
              <a-button> <a-icon type="upload" /> 上传 </a-button>
            </div>
          </a-upload>
        </a-form-model-item>
        <!-- <a-form-model-item
          label="课件"
          help="注：支持的文件格式为：png、jpg、doc、docx、xls、xlsx、ppt、pptx、pdf，文件大小在20M以下。"
          >
          <a-upload
            name="file"
            accept="image/png,image/jpeg,.doc,.docx,.xls,.xlsx,.ppt,.pptx,application/pdf"
            :action="$api.file.uploadFile"
            :fileList="fileListFile"
            :beforeUpload="beforeUploadFile"
            @change="handleFileChange"
          >
            <div v-if="fileListFile.length < 1">
              <a-button> <a-icon type="upload" /> 上传 </a-button>
            </div>
          </a-upload>
        </a-form-model-item> -->
        <a-form-model-item label="课中习题" :wrapper-col="{ span: 19 }">
          <pxkeji-btn name="选择题目" type="primary" @click="selectSubject" />
          <a-table
            rowKey="subjectId"
            size="small"
            :columns="list.columns"
            :pagination="false"
            :dataSource="ejectList"
            :locale="{ emptyText: '暂无数据' }"
          >
            <template slot="subjectName" slot-scope="subjectName">
              <span :title="subjectName" v-html="subjectName"></span>
            </template>
            <template slot="subjectType" slot-scope="info">
              <span v-if="info.subjectType == 0">单选题</span>
              <span v-else-if="info.subjectType == 1">多选题</span>
              <span v-else>判断题</span>
            </template>
            <template slot="difficult" slot-scope="info">
              <span v-if="info.difficult == 0">简单</span>
              <span v-else-if="info.difficult == 1">中等</span>
              <span v-else>复杂</span>
            </template>
            <template slot="showMinute" slot-scope="text, record">
              <editable-cell
                :text="record.showMinute"
                suffixTxt="分"
                @change="onCellChange(record.subjectId, 'showMinute', $event)"
              />
              <editable-cell
                :text="record.showSecond"
                suffixTxt="秒"
                @change="onCellChange(record.subjectId, 'showSecond', $event)"
              />
            </template>
            <!-- <template slot="showSecond" slot-scope="info">
              <editable-cell
                :text="info.showSecond"
                suffixTxt="秒"
                @change="onCellChange(info.subjectId, 'showSecond', $event)"
              />
            </template> -->
            <span slot="action" slot-scope="info">
              <pxkeji-btn
                name="删除"
                :roles="[]"
                size="small"
                @click="removeSubject(info.id)"
                type="primary"
              />
            </span>
          </a-table>
        </a-form-model-item>

        <a-form-model-item :wrapper-col="{ span: 19, offset: 4 }">
          <pxkeji-btn name="保存" @click="handleSubmit(0)" class="cancelBtn" />
          <pxkeji-btn
            name="取消"
            type="default"
            @click="cancelClick"
            class="cancelBtn"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
    <a-modal
      title="选择题目"
      v-model="ejectVisible"
      width="80%"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <questionList @callbackMethod="questionSelected" :selList="ejectList" />
    </a-modal>
  </a-spin>
</template>

<script>
import TcVod from "vod-js-sdk-v6";
var _this;
const EditableCell = {
  template: `
      <div class="editable-cell">
        <div v-if="editable" class="editable-cell-input-wrapper">
          <a-input
            size="small"
            :value="value"
            @change="handleChange"
            @pressEnter="check"
            :style="{width: '60px'}"
            :suffix=suffixTxt
          />
          <a-icon
            type="check"
            class="editable-cell-icon-check"
            @click="check"
          />
        </div>
        <div v-else class="editable-cell-text-wrapper">
          {{ value || ' ' }}{{suffixTxt}}
          <a-icon type="edit" class="editable-cell-icon" @click="edit" />
        </div>
      </div>
    `,
  props: {
    text: String,
    suffixTxt: String,
  },
  data() {
    return {
      value: this.text,
      editable: false,
    };
  },
  methods: {
    handleChange(e) {
      const value = e.target.value;
      this.value = value;
    },
    check() {
      this.editable = false;
      this.$emit("change", this.value);
    },
    edit() {
      this.editable = true;
    },
  },
};
import questionList from "../examination/questionList.vue";
export default {
  props: {
    infos: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    let checkNum = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入"));
      } else if (!Number.isInteger(value)) {
        callback(new Error("必须是数字"));
      } else {
        callback();
      }
    };
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 17 },
      form: {
        sourceType: 0,
        hourTime: 0,
        videoUrl: [],
        hourCount: 1,
        isAudition: false,
      },
      rules: {
        title: [
          {
            required: true,
            message: "请输入课时标题",
            trigger: "blur",
          },
        ],
        hourTime: [
          {
            required: true,
            message: "请输入时长",
            trigger: "change",
          },
          { validator: checkNum, trigger: "change" },
        ],
        hourCount: [
          {
            required: true,
            message: "请输入学时数",
            trigger: "change",
          },
          { validator: checkNum, trigger: "change" },
        ],
        sourceType: [
          {
            required: true,
            message: "请选择视频资源类型",
            trigger: "blur",
          },
        ],
      },
      videoTypeList: [
        { name: "文件", type: 0 },
        { name: "链接", type: 1 },
      ],
      hourCountType: 1,
      isloading: false,
      loadingTip: "加载中...",
      fileListVideo: [],
      fileListFile: [],
      fileInfoList: [],
      ejectVisible: false,
      ejectList: [],
      list: {
        columns: [
          {
            dataIndex: "subjectName",
            title: "题干",
            align: "center",
            ellipsis: true,
            scopedSlots: { customRender: "subjectName" },
          },
          {
            dataIndex: "subjectType",
            title: "类型",
            align: "center",
            width: 80,
            scopedSlots: { customRender: "subjectType" },
          },
          {
            dataIndex: "difficult",
            title: "难度",
            align: "center",
            width: 80,
            scopedSlots: { customRender: "difficult" },
          },
          {
            dataIndex: "showMinute",
            title: "弹出时间",
            align: "center",
            width: 240,
            scopedSlots: { customRender: "showMinute" },
          },
          // {
          //   title: "弹出秒数",
          //   key: "showSecond",
          //   width: 110,
          //   align: "center",
          //   scopedSlots: { customRender: "showSecond" },
          // },
          {
            title: "操作",
            key: "action",
            width: 80,
            align: "center",
            scopedSlots: { customRender: "action" },
          },
        ], //表头
        datas: [], //数据
      },
    };
  },
  components: {
    questionList,
    EditableCell,
  },
  created() {
    window.getUploadSign = this.getUploadSign;
  },
  mounted() {
    _this = this;
    if (_this.infos.id != 0) {
      _this.getInfo();
    }
  },
  methods: {
    cancelClick() {
      _this.$emit("callbackMethod");
    },
    getHourCountType() {
      _this.$api.courseHour.getHourCountType().then((res) => {
        if (res.errorCode == _this.$msg.responseCode.success) {
          _this.hourCountType = res.data;
        }
      });
    },
    getInfo() {
      _this.isloading = true;
      _this.$api.courseHour
        .getById(_this.infos.id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.form = {
              ...res.data,
              hourTime: Number(res.data.hourTime),
              hourCount: Number(res.data.hourCount),
              isAudition: _this.$commen.changeIntBoolean(res.data.isAudition),
            };
            if (res.data.videoUrl && res.data.sourceType == 0) {
              _this.fileListVideo.push({
                uid: 1,
                name: "课时视频1.mp4",
                status: "done",
                url: res.data.videoUrl,
              });
            }
            if (res.data.fileInfo) {
              let fileInfo = JSON.parse(res.data.fileInfo);
              if (fileInfo && fileInfo.length > 0) {
                _this.fileListFile.push({
                  uid: 1,
                  name: fileInfo[0].fileName,
                  status: "done",
                  url: fileInfo[0].fileUrl,
                });
                _this.fileInfoList.push({
                  fileName: fileInfo[0].fileName,
                  fileUrl: fileInfo[0].fileUrl,
                  type: fileInfo[0].type,
                  size: fileInfo[0].fileName.size,
                });
              }
            }
            if (res.data.ejectList && res.data.ejectList.length > 0) {
              let ejects = res.data.ejectList;
              for (let i = 0; i < ejects.length; i++) {
                _this.ejectList.push({
                  subjectId: ejects[i].resourceId,
                  subjectName: ejects[i].resourceDetail.questContent,
                  subjectType: ejects[i].resourceDetail.subjectType,
                  difficult: ejects[i].resourceDetail.difficult,
                  showMinute: String(ejects[i].showMinute),
                  showSecond: String(ejects[i].showSecond),
                });
              }
            }
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    handleSubmit() {
      _this.$refs.courseHourForm.validate((valid) => {
        if (valid) {
          _this.isloading = true;
          _this.loadingTip = "保存中...";
          let formData = { ..._this.form };
          formData.type = 1;
          formData.courseId = _this.infos.courseId;
          formData.chapterId =
            _this.infos.chapterId == null ? 0 : _this.infos.chapterId;
          formData.isAudition = _this.$commen.changeBooleanInt(
            formData.isAudition
          );
          if (formData.sourceType == 0) {
            if (_this.fileListVideo && _this.fileListVideo.length > 0) {
              formData.videoUrl = _this.fileListVideo[0].url;
            }
          }
          if (formData.sourceType == 0 && formData.videoUrl.length == 0) {
            _this.$message.error("请上传视频文件");
            return;
          }
          if (_this.fileInfoList && _this.fileInfoList.length > 0) {
            formData.fileInfo = JSON.stringify(_this.fileInfoList);
          }
          formData.ejectList = [];
          if (_this.ejectList && _this.ejectList.length > 0) {
            for (let i = 0; i < _this.ejectList.length; i++) {
              let item = {
                resourceId: _this.ejectList[i].subjectId,
                resourceType: "subject",
                showMinute: _this.ejectList[i].showMinute,
                showSecond: _this.ejectList[i].showSecond,
                sortNo: i + 1,
              };
              if (_this.infos.id != 0) {
                item.hourId = _this.infos.id;
              }
              formData.ejectList.push(item);
            }
          }
          if (_this.infos.id != 0) {
            formData.id = _this.infos.id;
          }
          _this.$api.courseHour
            .saveOrUpdateHour(formData)
            .then((res) => {
              if (res.errorCode == _this.$msg.responseCode.success) {
                _this.$message.success(res.errorMsg);
                _this.$emit("callbackMethod");
              } else {
                _this.$message.error(res.errorMsg);
              }
              _this.isloading = false;
            })
            .catch(() => {
              _this.isloading = false;
            });
        } else {
          return false;
        }
      });
    },
    selectSubject() {
      _this.ejectVisible = true;
    },
    questionSelected(data) {
      _this.ejectVisible = false;
      for (let i = 0; i < data.length; i++) {
        data[i].editable = true;
        let flag = false;
        for (let j = 0; j < _this.ejectList.length; j++) {
          if (_this.ejectList[j].subjectId == data[i].subjectId) {
            flag = true;
            break;
          }
        }
        if (!flag) {
          _this.ejectList.push(data[i]);
        }
      }
    },
    removeSubject(id) {
      let idx;
      _this.ejectList.forEach((item, index) => {
        if (item.subjectId == id) {
          idx = index;
        }
      });
      _this.ejectList.splice(idx, 1);
    },
    onCellChange(subjectId, dataIndex, value) {
      let subjectList = _this.ejectList;
      let target = subjectList.find((item) => item.subjectId == subjectId);
      if (target) {
        target[dataIndex] = value;
        _this.ejectList = subjectList;
      }
    },
    beforeUpload(file) {
      const isMP4 = file.type === "video/mp4";
      if (!isMP4) {
        _this.$message.error("请上传mp4格式视频");
      }
      const isLt1G = file.size / 1024 / 1024 < 1024 * 2;
      if (!isLt1G) {
        _this.$message.error("视频文件大小不能大于2G");
      }
      return isMP4 && isLt1G;
    },
    getUploadSign() {
      return this.uploadSign;
    },
    async customUpload(file) {
      let _this = this;
      let res = await this.$api.courseHour.getUploadSign();
      this.uploadSign = res.data;
      const tcVod = new TcVod({
        getSignature: window.getUploadSign,
      });
      let fileName = file.file.name;
      const uploader = tcVod.upload({
        mediaFile: file.file,
        mediaName:
          _this.$commen.applicationId +
          "_" +
          _this.$commen.guid() +
          fileName.substring(fileName.lastIndexOf(".")),
      });
      uploader
        .done()
        .then((res) => {
          file.onSuccess();
          _this.form.videoId = res.fileId;
          _this.form.videoUrl = res.video.url;
        })
        .catch(() => {
          file.onError();
        });
    },
    handleVideoChange(info) {
      _this.fileListVideo = info.fileList;
      if (info.file.status == "done") {
        if (info.file.response.errorCode == "0000") {
          _this.form.hourTime = Number(info.file.response.data.duration);
          _this.fileListVideo[_this.fileListVideo.length - 1].url =
            info.file.response.data.path;
        } else {
          _this.$message.error("上传失败");
        }
      } else if (info.file.status == "error") {
        _this.$message.error("上传失败");
      }
    },
    beforeUploadFile(file) {
      let fileTypes = [
        "png",
        "jpg",
        "doc",
        "docx",
        "xls",
        "xlsx",
        "ppt",
        "pptx",
        "pdf",
      ];
      let isType = false;
      let fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (fileTypes.indexOf(fileType) != -1) {
        isType = true;
      } else {
        isType = false;
        _this.$message.error("只能上传指定的文件格式");
      }
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        _this.$message.error("文件不能超过20MB");
      }
      return isType && isLt20M;
    },
    handleFileChange(info) {
      _this.fileListFile = info.fileList;
      if (info.file.status == "done") {
        if (info.file.response.errorCode == "0000") {
          _this.fileInfoList = [];
          // 文件信息
          _this.fileInfoList.push({
            fileName: info.file.response.data.name,
            fileUrl: info.file.response.data.path,
            type: info.file.response.data.name.substring(
              info.file.response.data.name.lastIndexOf(".") + 1
            ),
            size: info.file.response.data.size,
          });
        } else {
          _this.$message.error("上传失败");
          _this.fileListFile = [];
        }
      } else if (info.file.status == "error") {
        _this.$message.error("上传失败");
        _this.fileListFile = [];
      }
    },
  },
};
</script>
<style>
.editable-cell {
  display: inline-block;
}
</style>