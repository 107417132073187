<template>
  <a-spin :tip="loadingTip" :spinning="isloading">
    <div>
      <a-row :gutter="24">
        <a-col :span="5" style="padding-right: 70px">
          <a-divider orientation="center">课程视频列表</a-divider>
          <!-- <a-list item-layout="horizontal" :data-source="videoList">
            <a-list-item slot="renderItem" slot-scope="item, index">
              <a-list-item-meta>
                <a
                  slot="title"
                  href="javascript:void(0)"
                  @click.prevent="playVideo(item.videoUrl)"
                  >{{ item.title }}</a
                >
              </a-list-item-meta>
            </a-list-item>
          </a-list> -->
          <div class="mulu">
            <div v-for="(item, index) in courseInfo.chapterList" :key="index">
              <h2>
                <span>章节{{ index + 1 }}</span
                ><span>{{ item.title }}</span>
              </h2>
              <ul>
                <li v-for="(hour, hourIndex) in item.hourList" :key="hourIndex">
                  <a
                    style="margin-left: 15px"
                    href="javascript:;"
                    :class="{ hit: hourId == hour.id }"
                    @click="playVideo(hour.videoUrl, hour.id)"
                    >{{ hour.title }}</a
                  >
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li
                  v-for="(hour, hourIndex) in courseInfo.hourList"
                  :key="hourIndex"
                >
                  <a
                    style="margin-left: 15px"
                    href="javascript:;"
                    :class="{ hit: hourId == hour.id }"
                    @click="playVideo(hour.videoUrl, hour.id)"
                    >{{ hour.title }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </a-col>
        <a-col :span="19">
          <video-player
            class="video-player vjs-custom-skin"
            ref="videoPlayer"
            :playsinline="true"
            :options="playerOptions"
          ></video-player>
        </a-col>
      </a-row>
    </div>
  </a-spin>
</template>
<script>
var that;

export default {
  name: "courseHourVideo",
  props: {
    id: String,
  },
  data() {
    return {
      expand: false, 
      winHeight: 500,
      isloading: false,
      loadingTip: "加载中...",
      videoList: [],
      chapterList: [],
      courseInfo: {},
      hourId: 0,
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], //可选择的播放速度
        autoplay: true, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4",
            src: "",
          },
        ],
        poster: "", //你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, //当前时间和持续时间的分隔符
          durationDisplay: true, //显示持续时间
          remainingTimeDisplay: false, //是否显示剩余时间功能
          fullscreenToggle: true, //全屏按钮
        },
      },
    };
  },
  components: {},
  mounted: function () {
    this.$nextTick(function () {
      that = this;
      if (that.id != "0") {
        that.getVideo();
      }
    });
  },
  watch: {
    selectRows: function (val) {},
  },
  methods: {
    toggle() {
      this.expand = !this.expand;
    },
    onSelect(selectedKeys, info) {
      console.log("info", info);
    },
    playVideo(url, hourId) {
      this.hourId = hourId;
      console.log('hourId',this.hourId)
      this.playerOptions.sources[0].src = url;
    },
    getVideo() {
      //获取列表
      that.isloading = true;
      that.$api.course
        .getCourseHourListByCourseId(that.id)
        .then((res) => {
          that.isloading = false;
          if (res.errorCode == that.$msg.responseCode.success) {
            that.courseInfo = res.data;
            if (res.data.hourList) {
              that.chapterList = res.data.hourList;
              if (that.chapterList.length > 0) {
                that.hourId = that.chapterList[0].id;
                that.playVideo(that.chapterList[0].videoUrl,that.hourId);
              }
            }
            if (res.data.chapterList) {
              that.chapterList = res.data.chapterList;
              if (that.chapterList.length > 0) {
                that.hourId = that.chapterList[0].hourList[0].id;
                
                that.playVideo( that.chapterList[0].hourList[0].videoUrl,that.hourId);
              }
            }
            console.log("hour.id",that.hourId)
          } else {
            that.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          this.isloading = false;
        });
    },
  },
};
</script>

<style >
@media screen and (max-width: 1366px) {
  .table input,
  .table select {
    width: 160px;
  }
}
.ant-table-fixed-left {
  border-right: solid 1px #cccccc;
}
.ant-tree-title {
  overflow: hidden;
}
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
.mainWrapper {
  box-sizing: border-box !important;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 10px 10px;
}
</style>
<style scoped>
.ant-form-item {
  margin-bottom: 10px;
}
.mulu h2 span {
  width: auto;
  float: left;
  line-height: 30px;
  font-size: 16px;
  color: #000000;
  font-weight: 400;
  margin: 0 20px 0 8px;
}

.mulu ul {
  width: 100%;
  float: left;
  padding: 15px 0;
}

.mulu ul li {
  width: 100%;
  float: left;
}

.mulu ul li i {
  float: left;
  line-height: 32px;
  color: #353535;
  margin: 0 17px;
}

.mulu ul li a {
  float: left;
  line-height: 32px;
}

.mulu ul li span {
  float: right;
  line-height: 32px;
  color: #353535;
  margin: 0 30px 0 0;
}

.mulu ul li:hover i {
  color: #f49d1a;
}

.mulu ul li:hover a {
  color: #f49d1a;
}

.mulu ul li:hover span {
  color: #f49d1a;
}
.hit {
  color: #f49d1a;
}
</style>

