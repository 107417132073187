<template>
  <div>
    <div class="mainWrapper clearfix">
      <div class="articleMeauleft">
        <a-divider orientation="left">题目分类</a-divider>
        <a-tree
          showLine
          checkStrictly
          defaultExpandAll
          :replaceFields="replaceFields"
          :treeData="treeDataCategory"
          @select="selectCategory"
        />
      </div>
      <div class="articleMainRight">
        <div class="pxkeji-right-box">
          <a-form class="ant-advanced-search-form">
            <a-row :gutter="24">
              <a-col :span="8">
                <a-form-item label="题目内容">
                  <a-input placeholder="请输入" v-model="search.question" />
                </a-form-item>
              </a-col>
              <a-col :span="8" :style="{ textAlign: 'left' }">
                <a-form-item>
                  <a-button type="primary" @click="searchList">搜索</a-button>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <div class="pxkeji-right-box">
          <a-table
            rowKey="id"
            size="small"
            bordered
            :columns="list.columns"
            :pagination="list.pagination"
            @change="onPageChange"
            :dataSource="list.datas"
            :loading="isloading"
            :rowSelection="{
              type: 'checkbox',
              columnWidth: 40,
              onSelect: onRowSelect,
              onSelectAll: onSelectAllRow,
              selectedRowKeys: selectRows,
            }"
            :locale="{ emptyText: '暂无数据' }"
          >
            <template slot="subjectType" slot-scope="subjectType">
              <span v-if="subjectType == 0">单选题</span>
              <span v-if="subjectType == 1">多选题</span>
              <span v-if="subjectType == 2">判断题</span>
            </template>
            <template slot="questContent" slot-scope="questContent">
              <span v-html="questContent"></span>
            </template>
          </a-table>
        </div>
      </div>
    </div>

    <a-row :gutter="24">
      <a-col :span="24" :style="{ textAlign: 'center' }">
        <a-button type="primary" @click="choseBack">选择并返回</a-button>
        <pxkeji-btn
          name="取消"
          type="default"
          @click="cancelClick"
          style="margin-left: 10px"
        />
      </a-col>
    </a-row>
  </div>
</template>
<script>
const columns = [
  {
    dataIndex: "categoryName",
    align: "center",
    title: "分类",
    width: 200,
  },
  {
    dataIndex: "subjectType",
    align: "center",
    title: "题目类型",
    width: 140,
    scopedSlots: { customRender: "subjectType" },
  },
  {
    dataIndex: "difficultName",
    align: "center",
    title: "难度等级",
    width: 140,
  },
  {
    dataIndex: "questContent",
    align: "center",
    ellipsis: true,
    title: "题目内容",
    scopedSlots: { customRender: "questContent" },
  },
];

let _this;
export default {
  name: "questionList",
  props: {
    selList: Array,
  },
  data() {
    return {
      treeDataCategory: [],
      replaceFields: { children: "list", title: "name", key: "id" },
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: true,
          showSizeChanger: true,
          total: 0,
          current: 1,
          pageSize: 10,
          showTotal: (total) => `共 ${total} 条`,
        },
      },
      search: {
        //搜索条件
        question: "",
        categorys: "",
      },
      selectRows: [], //选中项编号
      allSelectRows: [],
      isloading: false,
    };
  },
  mounted() {
    _this = this;
    _this.getTree();
    _this.allSelectRows = _this.selList;
    _this.GetList();
  },
  methods: {
    getTree() {
      _this.$api.questioncategory
        .getTree()
        .then((res) => {
          if (res.errorCode == this.$msg.responseCode.success) {
            res.data.forEach((item) => {
              item.id = Number(item.id);
            });
            _this.treeDataCategory = res.data;
          }
        })
        .catch(() => {});
    },
    selectCategory(selectedKeys) {
      _this.search.categorys = selectedKeys.join();
      _this.GetList();
    },
    cancelClick() {
      _this.$emit("callbackMethod");
    },
    GetList() {
      //获取列表
      _this.isloading = true;
      _this.$api.subject
        .getPageListForUse(
          _this.list.pagination.current,
          _this.list.pagination.pageSize,
          _this.search.question,
          _this.search.categorys
        )
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.list.datas = res.data.records;
            _this.list.pagination.total = Number(res.data.total);
            _this.list.datas.forEach((item) => {
              for (let i = 0; i < _this.allSelectRows.length; i++) {
                if (_this.allSelectRows[i].subjectId == item.id) {
                  _this.selectRows.push(item.id);
                  break;
                }
              }
            });
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    onPageChange(page) {
      //页面切换回调
      _this.list.pagination.current = page.current;
      _this.list.pagination.pageSize = page.pageSize;
      _this.GetList();
    },
    //table 多选
    onRowSelect(record, selected, selectedRows) {
      _this.selectRows = selectedRows.map((item) => item.id);
      if (selected) {
        _this.allSelectRows.push({
          subjectId: record.id,
          subjectName: record.questContent,
          subjectType: record.subjectType,
          difficult: record.difficult,
          difficultName: record.difficultName,
          categoryName: record.categoryName,
        });
      } else {
        for (let i = 0; i < _this.allSelectRows.length; i++) {
          if (_this.allSelectRows[i].subjectId == record.id) {
            _this.allSelectRows.splice(i, 1);
            break;
          }
        }
      }
    },
    onSelectAllRow(selected, selectedRows, changeRows) {
      if (selected) {
        _this.selectRows = selectedRows.map((item) => item.id);
        changeRows.forEach((item) => {
          _this.allSelectRows.push({
            subjectId: item.id,
            subjectName: item.questContent,
            subjectType: item.subjectType,
            difficult: item.difficult,
            difficultName: item.difficultName,
            categoryName: item.categoryName,
          });
        });
      } else {
        _this.selectRows = [];
        changeRows.forEach((item) => {
          for (let i = 0; i < _this.allSelectRows.length; i++) {
            if (_this.allSelectRows[i].subjectId == item.id) {
              _this.allSelectRows.splice(i, 1);
              break;
            }
          }
        });
      }
    },
    searchList() {
      _this.list.pagination.current = 1;
      _this.GetList();
    },
    //选择并返回
    choseBack() {
      _this.$emit("callbackMethod", _this.allSelectRows);
    },
  },
};
</script>

<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.mainWrapper {
  box-sizing: border-box !important;
}
.articleMeauleft {
  width: 20%;
  padding: 0 1%;
  min-height: 700px;
  background: #fff;
  float: left;
  margin-top: 24px;
}
.articleMainRight {
  width: 80%;
  float: right;
}
.ant-table-tbody td p {
  margin: 0;
}
</style>

