<template>
  <a-spin :tip="loadingTip" :spinning="isloading">
    <div>
      <a-form-model
        ref="courseChapterForm"
        :model="info"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
      <a-form-model-item label="课程名称">
          <a-input placeholder="" disabled v-model="infos.courseName"></a-input>
        </a-form-model-item>
        <a-form-model-item label="章节名称" prop="title">
          <a-input placeholder="请输入" v-model="info.title"></a-input>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 10, offset: 4 }">
          <pxkeji-btn name="保存" type="primary" @click="handleSubmit" />
          <pxkeji-btn
            name="取消"
            type="default"
            @click="cancelClick"
            class="cancelBtn"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-spin>
</template>

<script>
let _this;

export default {
  props: {
    infos: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isloading: false,
      loadingTip: "加载中...",
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      info: {
        title: "",
      },
      rules: {
        title: [{ required: true, message: "请输入章节名称", trigger: "blur" }],
      },
    };
  },
  mounted() {
    _this = this;
    console.log(_this.infos);
    if (_this.infos.id != 0) {
      _this.getInfo();
    }
  },
  methods: {
    cancelClick() {
      _this.$emit("callbackMethod");
    },
    getInfo() {
      _this.isloading = true;
      _this.$api.course
        .getByChapterId(_this.infos.id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.info = res.data;
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    handleSubmit() {
      _this.$refs.courseChapterForm.validate((valid) => {
        if (valid) {
          _this.isloading = true;
          _this.loadingTip = "保存中...";
          let fromData = {
            title: _this.info.title,
            courseId: _this.infos.courseId,
            parentId: _this.infos.parentId,
          };
          if (_this.infos.id != 0) {
            fromData.id = _this.infos.id;
          }
          //添加
          _this.$api.course
            .saveOrUpdateChapter(fromData)
            .then((res) => {
              if (res.errorCode == _this.$msg.responseCode.success) {
                _this.$message.success(res.errorMsg);
                _this.$emit("callbackMethod");
              } else {
                _this.$message.error(res.errorMsg);
              }
              _this.isloading = false;
            })
            .catch(() => {
              _this.isloading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style>
</style>