<template>
  <div>
    <div class="mainWrapper clearfix">
      <div class="articleMeauleft" :style="height">
        <a-divider orientation="left">题目分类</a-divider>
        <a-tree
          :replaceFields="replaceFields"
          :treeData="treeDataCategory"
          checkable
          @check="checkCategory"
        />
      </div>
      <div class="articleMainRight">
        <div class="pxkeji-right-box">
          <a-form class="ant-advanced-search-form">
            <a-row :gutter="24">
              <a-col :span="8">
                <a-form-item label="题目内容">
                  <a-input
                    placeholder="请输入"
                    v-model="where.keyword.question"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="8" :style="{ textAlign: 'left' }">
                <a-button type="primary" @click="searchList">搜索</a-button>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <div class="pxkeji-right-box">
          <a-table
            :columns="list.columns"
            rowKey="id"
            :pagination="list.pagination"
            @change="onChange"
            :dataSource="list.datas"
            :loading="isloading"
            :rowSelection="{
              type: 'checkbox',
              onChange: onRowChange,
              selectedRowKeys: selectRows,
            }"
            :locale="{ emptyText: '暂无数据' }"
          >
            <span slot="subjectType" slot-scope="subjectType">
              <span v-if="subjectType == 0">单选题</span>
              <span v-if="subjectType == 1">多选题</span>
              <span v-if="subjectType == 2">判断题</span>
            </span>
          </a-table>
        </div>
      </div>
    </div>

    <a-row :gutter="24">
      <a-col :span="24" :style="{ textAlign: 'center' }">
        <a-button type="primary" @click="choseBack">选择并返回</a-button>
        <pxkeji-btn
          name="取消"
          type="default"
          @click="cancelClick"
          style="margin-left: 10px"
        />
      </a-col>
    </a-row>
  </div>
</template>
<script>
const columns = [
  {
    dataIndex: "categoryName",
    key: "categoryName",
    align: "center",
    title: "分类",
  },
  {
    dataIndex: "subjectType",
    align: "center",
    title: "题目类型",
    width: 200,
    scopedSlots: { customRender: "subjectType" },
  },
  {
    dataIndex: "difficultName",
    align: "center",
    title: "难度等级",
    width: 200,
  },
  {
    dataIndex: "questContent",
    align: "center",
    width: 200,
    ellipsis: true,
    title: "题目内容",
  },
];

export default {
  name: "storelist",
  props: {
    examinationId: String,
    selList: Array,
  },
  data() {
    return {
      height: {
        height: "",
      },
      treeDataCategory: [],
      saveHourSubject: [],
      replaceFields: { children: "list", title: "name", key: "id" },
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          //分页器
          showQuickJumper: true,
          hideOnSinglePage: true,
          total: 0,
          showTotal: (total) => {
            return "共 " + total + " 条";
          },
          defaultCurrent: 1, //页码
          pageSize: 10, //页容
        },
      },
      where: {
        //查询条件
        keyword: {
          //搜索条件
          question: "",
          categorys: "",
        },
      },
      selectRows: [], //选中项编号
      allSelectRows: [],
      isloading: false,
    };
  },
  mounted: function () {
    this.$nextTick(function () {
      this.getTree();
      // this.allSelectRows = this.selList;
      this.GetList();
    });
  },
  watch: {
    selectRows: function () {
      //console.log("watch", val);
    },
  },
  methods: {
    getTree() {
      this.$api.questioncategory
        .getTree()
        .then((res) => {
          if (res.errorCode == this.$msg.responseCode.success) {
            res.data.forEach((item) => {
              item.id = Number(item.id);
            });
            var tree = [
              {
                id: 0,
                name: "全部",
                list: res.data,
              },
            ];
            this.treeDataCategory = tree;
          } else {
            this.error(res.errorMsg);
          }
        })
        .catch(() => {
          this.isloading = false;
        });
    },
    checkCategory(checkedKeys) {
      this.where.keyword.categorys = checkedKeys.join();
      this.GetList();
    },
    toggle() {
      this.expand = !this.expand;
    },
    cancelClick() {
      this.$emit("callbackMethod");
    },
    GetList() {
      //获取列表
      this.isloading = true;
      this.clearRows();
      this.$api.subject
        .getPageList(
          this.list.pagination.defaultCurrent,
          this.list.pagination.pageSize,
          this.where.keyword.question,
          this.where.keyword.categorys
          // this.examinationId
        )
        .then((res) => {
          this.isloading = false;
          if (res.errorCode == this.$msg.responseCode.success) {
            this.list.datas = res.data.records;
            this.list.pagination.total = Number(res.data.total);
            for (var index in this.list.datas) {
              for (var i in this.allSelectRows) {
                if (
                  this.allSelectRows[i].subjectId == this.list.datas[index].id
                ) {
                  this.selectRows.push(this.allSelectRows[i].subjectId);
                }
              }
            }
            res.data.records.forEach((item) => {
              if (item.optionConf) {
                var opArr = [];
                // console.log(JSON.parse(item.optionConf));
                for (let i in JSON.parse(item.optionConf)) {
                  opArr.push(i + ":" + JSON.parse(item.optionConf)[i]);
                }
                // console.log(opArr.join(','));
                item.optionConf = opArr.join(" ");
              }
            });
          } else {
            this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          this.isloading = false;
        });
    },
    treeChange(val) {
      this.where.keyword.categoryId = val;
    },

    onChange(page) {
      //页面切换回调
      this.list.pagination.defaultCurrent = page.current;
      this.GetList();
    },
    onRowChange(selectedRowKeys) {
      //table 多选
      this.selectRows = selectedRowKeys;

      for (var data in this.list.datas) {
        var check = false;
        for (var key in selectedRowKeys) {
          if (this.list.datas[data].id == selectedRowKeys[key]) {
            check = true;
            break;
          }
        }

        if (check) {
          var find = false;
          for (var index in this.allSelectRows) {
            if (
              this.allSelectRows[index].subjectId == this.list.datas[data].id
            ) {
              find = true;
              break;
            }
          }
          if (!find) {
            this.allSelectRows.push({
              subjectId: this.list.datas[data].id,
              subjectName: this.list.datas[data].questContent,
              subjectType: this.list.datas[data].subjectType,
            });
          }
        } else {
          for (var index1 in this.allSelectRows) {
            if (
              this.allSelectRows[index1].subjectId == this.list.datas[data].id
            ) {
              this.allSelectRows.splice(index1, 1);
            }
          }
        }
      }
    },
    clearRows() {
      //清空选中项
      this.selectRows = [];
    },
    searchList() {
      for (var key in this.allSelectRows) {
        delete this.allSelectRows[key];
      }
      this.selectRows = [];
      this.list.pagination.defaultCurrent = 1;
      this.GetList();
    },
    clear() {
      //清空搜索条件
      for (var info in this.where.keyword) {
        this.where.keyword[info] = "";
      }
    },
    //选择并返回
    choseBack() {
      console.log(this.allSelectRows);
      this.$emit("callbackMethod", this.allSelectRows);
    },
  },
};
</script>

<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.cang_sta {
  text-align: center;
}

.cang_sta img {
  width: 35px;
}

.mainWrapper {
  box-sizing: border-box !important;
}
.articleMeauleft {
  width: 20%;
  padding: 0 1%;
  min-height: 700px;
  background: #fff;
  float: left;
  margin-top: 24px;
}
.articleMainRight {
  width: 80%;
  float: right;
}
</style>

