<template>
  <div>
    <a-spin :tip="loadingTip" :spinning="loading">
      <a-form-model
        ref="coursePicForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="课程名称">
          <span>{{ form.content }}</span>
        </a-form-model-item>
        <a-form-model-item label="封面模板" prop="picTempUrl">
          <a-radio-group v-model="form.picTempUrl">
            <a-radio
              :value="item"
              v-for="(item, index) in tempList"
              :key="index"
              class="radioItem"
            >
              <img :src="item" class="tempImg" />
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="文字行数">
          <a-input-number
            v-model="lineNum"
            :min="1"
            :max="5"
            @change="onLineChange"
          />
        </a-form-model-item>
        <a-row>
          <a-col :span="2" :offset="4">字符数</a-col>
          <a-col :span="3" :offset="1">字体</a-col>
          <a-col :span="2" :offset="1">颜色</a-col>
          <a-col :span="2" :offset="1">字号</a-col>
          <a-col :span="2" :offset="1">行高</a-col>
          <a-col :span="3" :offset="1">是否加粗</a-col>
        </a-row>
        <a-row
          v-for="(item, index) in form.itemList"
          :key="index"
          style="margin-top: 5px"
        >
          <a-col :span="2" :offset="2">{{ `第${index + 1}行：` }}</a-col>
          <a-col :span="2">
            <a-input-number
              placeholder="字符数"
              :min="1"
              :max="nameLength"
              size="small"
              v-model="item.wordNum"
            />
          </a-col>
          <a-col :span="3" :offset="1">
            <a-select placeholder="字体" size="small" v-model="item.typeFace">
              <a-select-option value="宋体">宋体</a-select-option>
              <a-select-option value="微软雅黑">微软雅黑</a-select-option>
              <a-select-option value="楷体">楷体</a-select-option>
              <a-select-option value="黑体">黑体</a-select-option>
              <a-select-option value="隶书">隶书</a-select-option>
            </a-select>
          </a-col>
          <a-col :span="2" :offset="1">
            <a-select placeholder="颜色" size="small" v-model="item.color">
              <a-select-option value="RGB(255,255,255)"> 白色 </a-select-option>
              <a-select-option value="RGB(0,0,0)"> 黑色 </a-select-option>
              <a-select-option value="RGB(255,0,0)"> 红色 </a-select-option>
              <a-select-option value="RGB(255,255,0)"> 黄色 </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="2" :offset="1">
            <a-input-number
              placeholder="字号"
              :min="1"
              :max="50"
              size="small"
              v-model="item.fontSize"
            />
          </a-col>
          <a-col :span="2" :offset="1">
            <a-select placeholder="行高" size="small" v-model="item.lineHeight">
              <a-select-option :value="1"> 1倍 </a-select-option>
              <a-select-option :value="1.3"> 1.3倍 </a-select-option>
              <a-select-option :value="1.5"> 1.5倍 </a-select-option>
              <a-select-option :value="1.7"> 1.7倍 </a-select-option>
              <a-select-option :value="2"> 2倍 </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="3" :offset="1">
            <a-switch
              checked-children="加粗"
              un-checked-children="正常"
              v-model="item.bold"
            />
          </a-col>
        </a-row>
        <a-form-model-item
          :wrapper-col="{ span: 18, offset: 4 }"
          style="margin-top: 10px"
        >
          <pxkeji-btn
            name="保存"
            type="primary"
            @click="coursePic('save')"
            v-if="id != null"
          />
          <pxkeji-btn
            name="确定"
            type="primary"
            @click="coursePic('upload')"
            v-if="id == null"
          />
          <pxkeji-btn
            name="预览"
            style="margin-left: 10px"
            @click="coursePic('preview')"
          />
        </a-form-model-item>
      </a-form-model>
    </a-spin>
    <a-modal
      title="封面图预览"
      :visible="previewImgVisible"
      width="600px"
      :footer="null"
      @cancel="handleImgCancel"
    >
      <img alt="example" style="width: 100%" :src="previewImgUrl" />
    </a-modal>
  </div>
</template>
<script>
let _this;
export default {
  props: {
    id: String,
    name: String,
  },
  name: "CoursePicConfig",
  data() {
    return {
      nameLength: this.name.trim().length,
      loading: false,
      loadingTip: "加载中...",
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      form: {
        courseId: this.id,
        content: this.name.trim(),
        picTempUrl: "",
        itemList: [
          {
            wordNum: this.name.trim().length,
            typeFace: "宋体",
            color: "RGB(255,255,255)",
            fontSize: null,
            lineHeight: 1.5,
            bold: false,
          },
        ],
      },
      lineNum: 1,
      tempList: [],
      rules: {
        picTempUrl: [
          { required: true, message: "请选择模板", target: "change" },
        ],
      },
      previewImgVisible: false,
      previewImgUrl: "",
    };
  },
  mounted() {
    _this = this;
    _this.getCoursePicTemp();
  },
  methods: {
    getCoursePicTemp() {
      _this.$api.course.getCoursePicTemp().then((res) => {
        _this.tempList = res.data;
      });
    },
    onLineChange(val) {
      let diff = val - _this.form.itemList.length;
      if (diff > 0) {
        for (let i = 0; i < diff; i++) {
          _this.form.itemList.push({
            wordNum: null,
            typeFace: "宋体",
            fontSize: null,
            bold: false,
          });
        }
      } else if (diff < 0) {
        _this.form.itemList.splice(_this.form.itemList.length + diff);
      }
    },
    coursePic(type) {
      _this.$refs.coursePicForm.validate((valid) => {
        if (valid) {
          if (!_this.lineNum) {
            _this.$message.error("请输入文字行数");
            return false;
          }
          let m = "";
          let words = 0;
          for (let i = 0; i < _this.form.itemList.length; i++) {
            let item = _this.form.itemList[i];
            if (item.wordNum == null) {
              m += `请设置第${i + 1}行的字符数；`;
            }
            if (item.fontSize == null) {
              m += `请设置第${i + 1}行的字号；`;
            }
            if (item.wordNum != null) {
              words += item.wordNum;
            }
          }
          if (m != "") {
            _this.$message.error(m);
            return false;
          }
          if (words > _this.nameLength) {
            _this.$message.error("各行字符数之和不能大于课程名称总字符数");
            return false;
          }
          _this.loading = true;
          if (type == "preview") {
            _this.loadingTip = "预览图生成中...";
            _this.$api.course
              .previewCoursePic(_this.form)
              .then((res) => {
                let blob = new Blob([res], { type: "image/png" });
                _this.previewImgUrl = (
                  window.URL || window.webkitURL
                ).createObjectURL(blob);
                _this.loading = false;
                _this.previewImgVisible = true;
              })
              .catch(() => {
                _this.loading = false;
              });
          } else if (type == "save") {
            _this.loadingTip = "保存中...";
            _this.$api.course
              .saveCoursePicAsync(_this.form)
              .then((res) => {
                if (res.errorCode == _this.$msg.responseCode.success) {
                  _this.$message.success(res.errorMsg);
                  _this.loading = false;
                  _this.$emit("callbackMethod");
                }
              })
              .catch(() => {
                _this.loading = false;
              });
          } else if (type == "upload") {
            _this.loadingTip = "封面图生成中...";
            _this.$api.course
              .getCoursePicPath(_this.form)
              .then((res) => {
                if (res.errorCode == _this.$msg.responseCode.success) {
                  _this.$message.success(res.errorMsg);
                  _this.loading = false;
                  _this.$emit("callbackMethod", res.data);
                }
              })
              .catch(() => {
                _this.loading = false;
              });
          }
        } else {
          return false;
        }
      });
    },
    handleImgCancel() {
      _this.previewImgVisible = false;
    },
  },
};
</script>
<style>
.tempImg {
  width: 120px;
}
.radioItem {
  margin: 3px 0;
}
</style>