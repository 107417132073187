<template>
  <a-spin :tip="loadingTip" :spinning="isloading" class="cmswrapper">
    <div>
      <div>
        <div class="questionList">
          <a-row :gutter="24">
            <a-col :span="8">
              <a-button-group>
                <a-button type="primary" @click="questionChoose"
                  >添加题目</a-button
                >
              </a-button-group>
            </a-col>
          </a-row>
          <a-table
            :columns="list.columns"
            rowKey="subjectId"
            :pagination="list.pagination"
            :dataSource="list.datas"
            :loading="isloading"
            :locale="{ emptyText: '暂无数据' }"
          >
            <template slot="scoreInfo" slot-scope="info">
              <editable-cell
                :text="info.score"
                @change="onCellChange(info.subjectId, 'score', $event)"
              />
            </template>
            <span slot="subjectType" slot-scope="subjectType">
              <span v-if="subjectType.subjectType == 0">单选题</span>
              <span v-else-if="subjectType.subjectType == 1">多选题</span>
              <span v-else>判断题</span>
            </span>
            <span slot="action" slot-scope="info">
              <a-popconfirm
                title="确定要删除吗?"
                @confirm="() => removeInfo(info.subjectId)"
              >
                <pxkeji-btn
                  name="刪除"
                  size="small"
                  type="link"
                  style="color: #ff4d4f"
                />
              </a-popconfirm>
            </span>
          </a-table>
        </div>

        <a-modal
          title="选择题目"
          v-model="questionList.visible"
          width="80%"
          :footer="false"
          destroyOnClose
          :maskClosable="false"
        >
          <questionList @callbackMethod="getquestion" />
        </a-modal>
      </div>
    </div>
  </a-spin>
</template>

<script>
import questionList from "../element/chooseQuestion.vue"; //选题

var that;
const EditableCell = {
  template: `
      <div class="editable-cell">
        <div v-if="editable" class="editable-cell-input-wrapper">
            <a-input
            placeholder="请输入"
           :value="value"
            @change="handleChange"
            @pressEnter="check"
            
          ></a-input>
          <a-icon
            type="check"
            class="editable-cell-icon-check"
            @click="check"
          />
        </div>
        <div v-else class="editable-cell-text-wrapper">
          <span v-text="value"></span>
          <a-icon type="edit" class="editable-cell-icon" @click="edit" />
        </div>
      </div>
    `,
  props: {
    text: Number,
  },
  data() {
    return {
      value: this.text,
      editable: false,
    };
  },
  watch: {
    text(val) {
      this.value = val;
    },
  },
  methods: {
    handleChange(e) {
      const value = e.target.value;
      this.value = value;
    },
    check() {
      this.editable = false;
      this.$emit("change", this.value);
    },
    edit() {
      this.editable = true;
    },
  },
};
const columns = [
  {
    dataIndex: "questContent",
    key: "questContent",
    align: "center",
    ellipsis: true,
    width: 300,
    title: "题目内容",
  },
  {
    title: "分数",
    key: "score",
    align: "center",
    scopedSlots: { customRender: "scoreInfo" },
  },
  {
    title: "类型",
    key: "subjectType",
    align: "center",
    scopedSlots: { customRender: "subjectType" },
  },
  {
    title: "操作",
    key: "action",
    width: 200,
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  props: {
    id: String,
  },
  data() {
    return {
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          //分页器
          showQuickJumper: true,
          hideOnSinglePage: true,
          total: 0,
          defaultCurrent: 1, //页码
          pageSize: 10, //页容
        },
      },
      singleScore: 0,
      multiScore: 0,
      judgeScore: 0,
      multiTotalCount: 0,
      singleTotalCount: 0,
      judgeTotalCount: 0,
      info: {
        categoryId: [0],
        examId: 0,
        subjectIds: "",
      },
      treeData: [],
      joblist: [],
      isloading: false,
      loadingTip: "加载中...",
      ansArr: [{ type: 1 }, { type: 2 }, { type: 3 }, { type: 4 }],
      examList: {
        visible: false,
      },

      questionList: {
        visible: false,
        selList: [],
      },
      quesList: [],
      question: [],
      question_limit: 0,
    };
  },
  components: {
    questionList, //选题
    EditableCell,
  },
  mounted() {
    this.$nextTick(function () {
      that = this;
      this.question_limit = this.limit;
      if (this.id != 0) {
        //编辑
        this.getList();
      }
    });
  },
  methods: {
    /**取消 */
    cancelClick() {
      this.$emit("callbackMethod");
    },
    //选题
    questionChoose() {
      this.questionList.visible = true;
      this.questionList.selList = this.question;
    },
    getquestion(data) {
      this.questionList.visible = false;
      if (data) {
        for (var index in data) {
          data[index].courseId = this.id;
          data[index].score = 0;
        }

        that.saveHourSubject(data);
      }
    },
    /**获取详情 */
    getList() {
      this.isloading = true;
      this.$api.classhour
        .getHourSubject(0, this.id)
        .then((res) => {
          this.isloading = false;
          if (res.errorCode == this.$msg.responseCode.success) {
            this.list.datas = res.data;
            this.info = res.data;
            this.list.pagination.total = res.data.length;
          } else {
            this.error(res.errorMsg);
          }
        })
        .catch(() => {
          this.isloading = false;
        });
    },
    saveHourSubject(data) {
      var string = JSON.stringify(data);
      this.isloading = true;
      this.$api.classhour
        .saveHourSubject(string)
        .then((res) => {
          this.isloading = false;
          if (res.errorCode == this.$msg.responseCode.success) {
            this.getList();
            this.sucess(res.errorMsg);
          } else {
            this.error(res.errorMsg);
          }
        })
        .catch(() => {
          this.isloading = false;
        });
    },
    removeInfo(id) {
      this.isloading = true;
      this.$api.classhour
        .deleteHourSubject(0, id, this.id)
        .then((res) => {
          this.isloading = false;
          if (res.errorCode == this.$msg.responseCode.success) {
            this.getList();
            this.sucess(res.errorMsg);
          } else {
            this.error(res.errorMsg);
          }
        })
        .catch(() => {
          this.isloading = false;
        });
    },
    handleSubmit(e) {},
    //子组件传值
    save() {
      this.info.visible = false;
    },
    onCellChange(subjectId, dataIndex, score) {
      console.log(subjectId);
      this.isloading = true;
      this.$api.classhour
        .updateHourSubject(0, subjectId, score, this.id)
        .then((res) => {
          this.isloading = false;
          if (res.errorCode == this.$msg.responseCode.success) {
            that.$message.success(res.errorMsg);
          } else {
            this.error(res.errorMsg);
          }
        })
        .catch(() => {
          this.isloading = false;
        });
    },
    error(msg) {
      this.$error({
        title: "提示",
        content: msg == undefined ? "服务器异常" : msg,
        okText: "返回",
        centered: true,
        onOk() {
          that.$emit("callbackMethod");
        },
      });
    },
  },
};
</script>
<style>
.ant-cascader-menu {
  height: 320px;
}
.questionList {
  margin-bottom: 30px;
}
.editable-cell {
  position: relative;
}

.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  padding-right: 24px;
}

.editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell-icon,
.editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}

.editable-cell-icon {
  line-height: 18px;
  /* display: none; */
}

.editable-cell-icon-check {
  line-height: 28px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}
.editable-add-btn {
  margin-bottom: 8px;
}
</style>