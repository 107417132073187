<template>
  <a-spin :tip="loadingTip" :spinning="isloading">
    <div>
      <div style="position: relative">
        <a-form-model
          ref="courseForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="所属分类" prop="typeIds">
            <a-tree-select
              placeholder="请选择"
              allowClear
              multiple
              show-search
              treeNodeFilterProp="title"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              tree-default-expand-all
              v-model="form.typeIds"
              :tree-data="treeData"
              :replaceFields="{
                title: 'name',
                value: 'id',
                children: 'children',
              }"
            />
          </a-form-model-item>
          <a-form-model-item label="课程名称" prop="name">
            <a-input
              placeholder="请输入"
              v-model="form.name"
              :maxLength="100"
            ></a-input>
          </a-form-model-item>
          <a-form-model-item
            v-show="
              (form.typeIds[0] == 70 && item.groupId == 46) ||
              (form.typeIds[0] == 71 && item.groupId == 45)
            "
            v-for="(item, index) in groupList"
            :key="index"
            :label="item.name"
          >
            <!-- <a-select
              v-model="item.defaultValue"
              placeholder="请选择"
              @change="onChange"
            >
              <template v-for="tag in item.dictionaryItemList">
                <a-select-option :value="tag.id" :key="tag.itemName">
                  {{ tag.itemName }}
                </a-select-option>
              </template>
            </a-select> -->
            <a-cascader
              placeholder="请选择"
              :options="item.dictionaryItemList"
              allowClear
              v-model="item.defaultValue"
              @change="onChange"
              :fieldNames="{
                label: 'itemName',
                value: 'id',
                children: 'children',
              }"
            />
          </a-form-model-item>
          <a-form-model-item label="主讲教师" prop="teacherId">
            <a-select v-model="form.teacherId" placeholder="请选择">
              <a-select-option
                :value="info.id"
                v-for="(info, index) in teacherlist"
                :key="index"
                >{{ info.fullName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="学时" prop="hourCount">
            <a-input
              placeholder="请输入"
              v-model.number="form.hourCount"
              :maxLength="5"
            ></a-input>
          </a-form-model-item>
          <a-form-model-item label="标签">
            <a-select v-model="form.tag" placeholder="请选择" mode="multiple">
              <template v-for="tag in tagList">
                <a-select-option :value="tag.itemName" :key="tag.id">
                  {{ tag.itemName }}
                </a-select-option>
              </template>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="是否免费" prop="isGratis">
            <a-switch
              v-model="form.isGratis"
              checked-children="是"
              un-checked-children="否"
            />
          </a-form-model-item>
          <a-row v-if="!form.isGratis">
            <a-col :span="12">
              <a-form-model-item
                label="原价"
                :labelCol="{ span: 8 }"
                :wrapperCol="{ span: 10 }"
                prop="originalPrice"
              >
                <a-input
                  placeholder="请输入"
                  v-model="form.originalPrice"
                  :maxLength="10"
                ></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item
                label="现价"
                :labelCol="{ span: 4 }"
                :wrapperCol="{ span: 10 }"
                prop="presentPrice"
              >
                <a-input
                  placeholder="请输入"
                  v-model="form.presentPrice"
                  :maxLength="10"
                ></a-input>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-model-item
                label="是否公开"
                :labelCol="{ span: 8 }"
                :wrapperCol="{ span: 10 }"
                prop="open"
              >
                <a-switch
                  v-model="form.open"
                  checked-children="是"
                  un-checked-children="否"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item
                label="是否必修"
                :labelCol="{ span: 4 }"
                :wrapperCol="{ span: 10 }"
                prop="required"
              >
                <a-switch
                  v-model="form.required"
                  checked-children="是"
                  un-checked-children="否"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-model-item
                label="封面图"
                :labelCol="{ span: 8 }"
                :wrapperCol="{ span: 12 }"
                help="注：建议图片尺寸270像素X165像素"
              >
                <a-upload
                  accept="image/png,image/jpeg"
                  listType="picture-card"
                  :action="$api.file.uploadFile"
                  :fileList="fileList"
                  :beforeUpload="beforeUpload"
                  @preview="handlePreview"
                  @change="handleChange"
                >
                  <div v-if="fileList.length == 0">
                    <a-icon type="plus" />
                    <div class="ant-upload-text">Upload</div>
                  </div>
                </a-upload>
                <a-button
                  style="position: relative; top: -10px"
                  @click="toCreatePic"
                  >使用模板</a-button
                >
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item
                label="预告片"
                :labelCol="{ span: 4 }"
                :wrapperCol="{ span: 14 }"
                help="注：仅支持mp4格式，大小在100M以下。"
              >
                <a-upload
                  accept="video/mp4"
                  :action="$api.file.uploadFile"
                  :fileList="fileListVideo"
                  :beforeUpload="beforeUploadVideo"
                  @change="handleVideoChange"
                >
                  <div v-if="fileListVideo.length < 1">
                    <a-button>
                      <a-icon type="upload" />
                      上传
                    </a-button>
                  </div>
                </a-upload>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-form-model-item label="课程简介" prop="introduce">
            <a-textarea
              placeholder="请输入"
              v-model="form.introduce"
              :maxLength="500"
            />
          </a-form-model-item>
          <a-form-model-item label="pc详细介绍" prop="pcContent">
            <vue-ueditor-wrap
              v-model="form.pcContent"
              :config="myConfig"
            ></vue-ueditor-wrap>
          </a-form-model-item>
          <a-form-model-item label="app详细介绍" prop="appContent">
            <vue-ueditor-wrap
              v-model="form.appContent"
              :config="myConfig"
            ></vue-ueditor-wrap>
          </a-form-model-item>

          <a-form-model-item :wrapper-col="{ span: 19, offset: 4 }">
            <pxkeji-btn
              name="保存"
              @click="handleSubmit(0)"
              class="cancelBtn"
            />
            <pxkeji-btn
              name="取消"
              type="default"
              @click="cancelClick"
              class="cancelBtn"
            />
          </a-form-model-item>
        </a-form-model>
      </div>
      <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
        <img alt="example" style="width: 100%" :src="form.picUrl" />
      </a-modal>
      <a-modal
        title="封面图配置"
        v-model="createPic.visible"
        width="900px"
        :footer="false"
        destroyOnClose
        :maskClosable="false"
      >
        <CoursePicConfig
          @callbackMethod="closeCreatePic"
          :id="createPic.id"
          :name="createPic.name"
        />
      </a-modal>
    </div>
  </a-spin>
</template>

<script>
import CoursePicConfig from "./CoursePicConfig.vue"; //
import VueUeditorWrap from "vue-ueditor-wrap";
let _this;
export default {
  name: "CourseInfo",
  props: {
    id: String,
    selectTypeId: null,
  },
  data() {
    let validatePrice = (rule, value, callback) => {
      if (!/^[0-9]+(\.[0-9]{0,2})?$/.test(value)) {
        callback(new Error("请输入两位以内小数的数字"));
      } else {
        if (!this.form.isGratis && value == 0) {
          callback(new Error("收费状态下价格不能为0"));
        } else {
          callback();
        }
      }
    };
    let checkNumber = (rule, value, callback) => {
      if (value) {
        if (!Number.isInteger(value)) {
          callback(new Error("请输入数字"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };

    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 17 },
      form: {
        typeId: null,
        typeIds: [],
        name: "",
        picUrl: "",
        videoUrl: "",
        courseCredit: 0,
        hourCount: 0,
        originalPrice: 0,
        presentPrice: 0,
        open: false,
        required: false,
        courseGroupItemList: [],
        tag: [],
      },

      tagList: [],
      rules: {
        typeIds: [
          { required: true, message: "请选择所属分类", trigger: "change" },
        ],
        name: [{ required: true, message: "请输入课程名称", trigger: "blur" }],
        hourCount: [{ validator: checkNumber, trigger: "change" }],
        originalPrice: [
          { required: true, validator: validatePrice, trigger: "change" },
        ],
        presentPrice: [
          { required: true, validator: validatePrice, trigger: "change" },
        ],
        introduce: [
          { required: true, message: "请输入课程简介", trigger: "blur" },
        ],
        pcContent: [
          { required: true, message: "请输入课程详细介绍", trigger: "blur" },
        ],
      },
      treeData: [],
      groupList: [],
      teacherlist: [],
      fileList: [],
      fileListVideo: [],
      previewVisible: false,
      isloading: false,
      loadingTip: "加载中...",
      myConfig: {
        autoHeightEnabled: false,
        initialFrameHeight: 300,
        wordCount: true,
        maximumWords: 100000,
        initialFrameWidth: "100%",
        serverUrl: this.$api.file.uploadUpFile,
        UEDITOR_HOME_URL: "/UEditor/",
        toolbars: this.$commen.editorConfig.toolbars,
      },
      createPic: {
        visible: false,
        name: "",
      },
    };
  },
  mounted() {
    _this = this;
    _this.getTypeTree(); // 分类
    _this.getCourseGroupList(); // 课程属性
    _this.getTeacherlist(); //教师列表
    -this.getTagList(); //标签列表
    if (_this.id != "0") {
      _this.getInfo();
    } else {
      if (_this.selectTypeId) {
        // _this.getParentChain(_this.selectTypeId);
      }
    }
  },
  components: {
    VueUeditorWrap,
    CoursePicConfig,
  },
  methods: {
    /**取消 */
    cancelClick() {
      _this.$emit("callbackMethod");
    },
    //分类
    async getTypeTree() {
      let res = await _this.$api.courseType.getUseTypeTree();
      if (res.errorCode == _this.$msg.responseCode.success) {
        _this.treeData = res.data;
        _this.setTypeSelectable(_this.treeData);
      } else {
        _this.$message.error(res.errorMsg);
      }
    },
    setTypeSelectable(treeData) {
      if (treeData) {
        treeData.forEach((item) => {
          item.id = String(item.id);
          if (item.children && item.children.length > 0) {
            item.selectable = false;
            _this.setTypeSelectable(item.children);
          }
        });
      }
    },
    //属性
    async getCourseGroupList() {
      let res = await _this.$api.course.getCourseGroupList();
      if (res.errorCode == _this.$msg.responseCode.success) {
        _this.groupList = res.data;
      } else {
        _this.$message.error(res.errorMsg);
      }
    },
    /**获取标签列表 */
    async getTagList() {
      let result = await _this.$api.dictionaryItem.tree(43);
      if (result.errorCode == _this.$msg.responseCode.success) {
        _this.tagList = result.data;
      } else {
        _this.$message.error(result.errorMsg);
      }
    },
    //详情
    getInfo() {
      _this.isloading = true;
      _this.$api.course
        .getById(_this.id)
        .then((res) => {
          _this.isloading = false;
          if (res.errorCode == _this.$msg.responseCode.success) {
            if (res.data.tag) {
              res.data.tag = res.data.tag.split(",");
            }
            res.data.typeId = res.data.typeId.split(",");
            _this.form = {
              ...res.data,
              isGratis: _this.$commen.changeIntBoolean(res.data.isGratis),
              open: _this.$commen.changeIntBoolean(res.data.open),
              required: _this.$commen.changeIntBoolean(res.data.required),
              typeIds: res.data.typeId,
            };

            // _this.getParentChain(res.data.typeId);
            let courseItems = _this.form.courseGroupItemList;
            if (courseItems && courseItems.length > 0) {
              _this.groupList.forEach((item) => {
                let result = courseItems.find(
                  (group) => group.groupId == item.groupId
                );
                if (result) {
                  let parentNode = _this.$commen.concatTree(
                    item.dictionaryItemList,
                    "id",
                    result.itemId
                  );
                  item.defaultValue = [];
                  parentNode.forEach((p) => {
                    item.defaultValue.push(p.id);
                  });
                  item.defaultValue.push(result.itemId);
                }
              });
            } else {
              this.form.courseGroupItemList = [];
            }

            if (res.data.picUrl) {
              _this.fileList.push({
                status: "done",
                url: res.data.picUrl,
                name: "coursePic.png",
                uid: 1,
              });
            }

            if (res.data.videoUrl) {
              _this.fileListVideo.push({
                uid: 1,
                name: res.data.videoUrl.substring(
                  res.data.videoUrl.lastIndexOf("/") + 1
                ),
                status: "done",
                url: res.data.videoUrl,
              });
            }
          } else {
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    getParentChain(id) {
      _this.$api.courseType
        .getParentIdList(id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.form.typeIds = res.data;
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    //获取教师列表
    getTeacherlist() {
      _this.isloading = true;
      _this.$api.teacher
        .getTeacherList()
        .then((res) => {
          _this.isloading = false;
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.teacherlist = res.data;
          } else {
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    handleSubmit() {
      if (_this.isloading) {
        return false;
      }
      _this.$refs.courseForm.validate((valid) => {
        if (valid) {
          _this.isloading = true;
          _this.loadingTip = "保存中...";
          // if (_this.fileList.length < 1) {
          //   _this.$message.error("请上传封面图");
          //   _this.isloading = false;
          //   return false;
          // }
          let formData = { ..._this.form };
          // formData.typeId = formData.typeIds[formData.typeIds.length - 1];
          formData.typeId = formData.typeIds.join();
          formData.tag = formData.tag ? formData.tag.join() : "";
          delete formData.typeIds;
          formData.classType = 0;
          formData.isGratis = _this.$commen.changeBooleanInt(formData.isGratis);
          formData.open = _this.$commen.changeBooleanInt(formData.open);
          formData.required = _this.$commen.changeBooleanInt(formData.required);
          _this.groupList.forEach((item) => {
            if (item.defaultValue) {
              let exit = false;
              let groupItem = {
                groupId: item.groupId,
                itemId: item.defaultValue[item.defaultValue.length - 1],
              };
              if (_this.id != 0) {
                groupItem.courseId = _this.id;
              }

              for (let i = 0; i < formData.courseGroupItemList.length; i++) {
                if (formData.courseGroupItemList[i].groupId == item.groupId) {
                  formData.courseGroupItemList.splice(i, 1, groupItem);
                  exit = true;
                  break;
                }
              }

              if (!exit) {
                formData.courseGroupItemList.push(groupItem);
              }
            }
          });
          if (_this.id != 0) {
            formData.id = _this.id;
          }
          // 编辑
          _this.$api.course
            .saveOrUpdate(formData)
            .then((res) => {
              if (res.errorCode == _this.$msg.responseCode.success) {
                _this.$message.success(res.errorMsg);
                _this.$emit("callbackMethod");
              } else {
                _this.$message.error(res.errorMsg);
              }
              _this.isloading = false;
            })
            .catch(() => {
              _this.isloading = false;
            });
        } else {
          return false;
        }
      });
    },
    /**上传课程图片 */
    handleCancel() {
      _this.previewVisible = false;
    },
    handlePreview() {
      _this.previewVisible = true;
    },
    handleChange(info) {
      _this.fileList = info.fileList;
      if (info.file.status == "done") {
        if (info.file.response.errorCode == "0000") {
          _this.form.picUrl = info.file.response.data.path;
        } else {
          _this.$message.error("上传失败");
        }
      } else if (info.file.status == "error") {
        _this.$message.error("上传失败");
      } else if (info.file.status == "removed") {
        _this.form.picUrl = "";
      }
    },
    beforeUpload(file) {
      let isJPGPNG = false;
      if (file.type === "image/jpeg" || file.type === "image/png") {
        isJPGPNG = true;
      } else {
        isJPGPNG = false;
        _this.$message.error("只能上传jpg/png格式的文件!");
      }
      let isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        _this.$message.error("文件不能超过2MB!");
      }
      return isJPGPNG && isLt2M;
    },
    beforeUploadVideo(file) {
      const isMP4 = file.type === "video/mp4";
      if (!isMP4) {
        _this.$message.error("只能上传mp4格式的视频");
      }
      const isLt100M = file.size / 1024 / 1024 < 100;
      if (!isLt100M) {
        _this.$message.error("视频大小不能超过100M");
      }
      return isMP4 && isLt100M;
    },
    handleVideoChange(info) {
      _this.fileListVideo = info.fileList;
      if (info.file.status == "done") {
        if (info.file.response.errorCode == "0000") {
          _this.form.videoUrl = info.file.response.data.path;
        } else {
          _this.$message.error("上传失败");
        }
      } else if (info.file.status == "error") {
        _this.$message.error("上传失败");
      } else if (info.file.status == "removed") {
        _this.form.videoUrl = "";
      }
    },
    onChange() {
      this.$forceUpdate();
    },
    toCreatePic() {
      if (_this.form.name == null || _this.form.name == "") {
        _this.$message.error("请填写课程名称");
        return false;
      }
      _this.createPic.name = _this.form.name;
      _this.createPic.visible = true;
    },
    closeCreatePic(path) {
      if (path) {
        _this.fileList = [];
        _this.form.picUrl = path;
        _this.fileList.push({
          status: "done",
          url: path,
          name: "coursePic.png",
          uid: 1,
        });
      }
      _this.createPic.visible = false;
    },
  },
};
</script>
<style>
.zjitem {
  border: 1px solid #e8e8e8;
  padding-left: 20px;
  cursor: pointer;
  margin-bottom: 10px;
}

.addzj,
.moveup,
.movedown {
  color: #1890ff;
}
</style>